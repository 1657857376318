﻿        html,
        body {
            font-size: rem-calc(16);
        }
        
        h2 {
            font-size: rem-calc(16);
        }
        
        a,
        p {
            font-size: rem-calc(14);
        }
        
        footer {
            background-color: rgb(43, 78, 137);
            min-height: 139px;
        }
        
        ul {
            margin: 0;
        }
        
        .top-toolbar,
        .main-navigation,
        .main-navigation__main-header,
        .search-block,
        .items-listing-block__accordion,
        .items-listing-block__pagination,
        .my-profile-body {
            border: 1px solid map-get($foundation-palette, secondary);
            border-top: 0px;
            border-bottom: 0px;
        }
        
        .left {
            float: left;
        }
        
        .right {
            float: right;
        }
        
        select[multiple] {
            min-height: 200px;
            background-image: none;
        }
        
        @media (max-width: 600px) {
            .mobile-close {
                display: none;
            }
            .mobile-75 {
                width: 75%;
            }
        }
        
        @media(max-width: 768) {
            .mobile-close {
                display: none;
            }
            .mobile-75 {
                width: 75%;
            }
        }