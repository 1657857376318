﻿@mixin form-button() {    
    color: $white;    
    background-color: map-get($foundation-palette, button-color);    
    -moz-border-radius: rem-calc(5);
    -webkit-border-radius: rem-calc(5);
    border-radius: rem-calc(5);    
    &:hover{
        background-color: map-get($foundation-palette, secondary);
        color: $white;
    }
}