﻿body {
    font-family: $body-font-family;
    font-size: 14px;
}

button,
input,
optgroup,
select,
textarea,
p {
    font-family: $body-font-family;
    font-size: rem-calc(14);
}

h1,
h2,
h3,
h4,
h5,
label {
    color: #333333;
}

.login-body {
    p {
        color: $white;
    }
}

.global-footer p {
    color: $white !important;
}

.login-form__field {
    label {
        color: $white;
    }
}

.top-toolbar {
    color: $white;
    p {
        margin-bottom: 0;
        padding-bottom: 6px;
        color: $white;
    }
    a {
        padding-bottom: 6px;
    }
    h3 {
        padding-bottom: 6px;
        color: $white;
    }
}

.top-toolbar__item-left,
.top-toolbar__item-center {
    padding-top: 7px;
}

.top-toolbar__item-left {
    font-size: rem-calc(16);
    font-weight: bold;
    margin-bottom: 0;
}

.top-toolbar__item-right {
    font-weight: bold;
    p {
        font-weight: bold;
        padding-top: 7px;
    }
    >div:last-child {
        padding-left: 0;
        padding-right: 0;
    }
    a {
        color: $white;
        text-align: center;
    }
}

.main-navigation__maing-header {
    font-size: rem-calc(32);
}

.main-navigation__item {
    color: $white;
    font-size: rem-calc(18);
    &:hover {
        color: $white;
    }
}

.user-messaging {
    color: lightslategrey;
    >div:last-child {
        span {
            color: map-get($foundation-palette, alert);
        }
        a {
            text-decoration: underline;
        }
        span.success {
            color: map-get($foundation-palette, success);
        }
    }
}

.my-profile-form-msg {
    margin-bottom: 20px;
}

.delete-user-form h2 {
    font-size: 2em !important;
    margin-bottom: 20px;
}