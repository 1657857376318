﻿    input {
        margin-bottom: 0;
    }
    
    .login-form__input-login {
        @include form-button();
    }
    
    .login-form__input-submit {
        @include form-button();
    }
    
    .top-toolbar__item-right {
        .item-right__sign-out {
            background-color: map-get($foundation-palette, anchor-blue);
            display: block;
            padding-top: rem-calc(10);
            &:hover {
                background-color: $black;
            }
        }
    }
    
    .main-navigation__item {
        &:hover {
            background-color: map-get($foundation-palette, anchor-blue);
        }
    }
    
    .search-block__add-user-button {
        @include form-button();
    }
    
    .button-light {
        background-color: $white;
        color: map-get($foundation-palette, primary);
        border-color: map-get($foundation-palette, primary);
        -moz-border-radius: rem-calc(5);
        -webkit-border-radius: rem-calc(5);
        border-radius: rem-calc(5);
    }
    
    .button-dark {
        border-radius: rem-calc(5);
    }
    
    .up-down-arrows {
        a {
            margin-right: 15px;
        }
    }
    
    .delete-user-form input a {
        margin-top: 40px;
    }
    
    .upload-file {
        padding: 5px;
        font-size: 12px;
    }
    
    .button.expanded {
        border-radius: 0.3125rem;
    }