.lead-report-body {
    border-bottom-color: map-get($foundation-palette, secondary);
    padding-top: rem-calc(30);
    padding-bottom: rem-calc(50);
    border: 1px solid map-get($foundation-palette, secondary);
    border-top: 0px;
    border-bottom: 0px;
}

.lead-report-body h4 {
    margin: rem-calc(30);
    margin-top: rem-calc(0);
}