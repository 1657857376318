h6,
h7 {
    font-weight: bold;
}

.dealer-status {
    border: 1px solid #F2F2F1;
    border-radius: 5px;
    padding: 10px 10px 0px;
}

.dealer-status i {
    margin-left: 20px;
    margin-right: 5px;
}

.dealer-status ul li {
    display: inline;
    background: white;
}

.location-hours {
    resize: none;
}

.contact-leads {
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 16px;
}

.dealer-contact-info div {
    margin-top: 10px;
    margin-bottom: 5px;
}

.dealer-contact-leads {
    margin-top: 20px;
}