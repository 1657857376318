.main-navigation__main-header{
    border-bottom: 35px solid $black;
    padding-top: 20px;
    padding-bottom: 10px;
    margin-bottom: 0;    
}

.search-block{
    background-color: map-get($foundation-palette, list-body);
    padding-top: 41px;       
    padding-bottom: 10px;
    border-bottom: 0;         
}

.search-block__add-user-button{
    @include breakpoint(medium up){
        margin-top: 24px;
    }  
}

.items-listing-block__accordion{        
    ul{
        li{
            background-color: map-get($foundation-palette, list-body);            
            .accordion-title{                
                border: 0;
                border-bottom: 3px solid $white;
            }            
            .accordion-content{
                border: 0;
            }
        }
    }    
}

.items-listing-block__pagination{
    padding-top: 40px;
    padding-bottom: 20px;
}

.items-listing-block__pagination--click{
    width: 45%;
    &:last-child{
        width: 10%;
    }   
}