.main-navigation{        
    background-color: #BABABA;
    ul{
        li{
            padding: 0;
            border-left: 1px solid $white;            
            @include breakpoint(small only) {
                border-bottom: 1px solid $white;
            }
        }
        list-style: none;
        border-right: 1px solid $white;
    }
    border-top: 1px solid $white;
    border-bottom: 1px solid $white;
}

.main-navigation__item{
    /*background-color: #BABABA;*/
    text-align: center;
    padding: 20px 0;
    display: block;    
}

.main-navigation__main-header{    
    padding-top: 15px;    
    margin-bottom: 0px;
    padding-bottom: 20px;
}