$font-path: "../fonts/foundation-icons/foundation-icons";
@font-face {
  font-family: "foundation-icons";
  src: url("#{$font-path}.eot");
  src: url("#{$font-path}.eot?#iefix") format("embedded-opentype"),
       url("#{$font-path}.woff") format("woff"),
       url("#{$font-path}.ttf") format("truetype"),
       url("#{$font-path}.svg#fontcustom") format("svg");
  font-weight: normal;
  font-style: normal;
}

@import "../fonts/foundation-icons/foundation-icons.css";