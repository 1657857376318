.my-profile-body {
    border-bottom-color: map-get($foundation-palette, secondary);
    padding-top: rem-calc(30);
    padding-bottom: rem-calc(50);
}

.delete-user-form-row {
    margin-top: 60px;
}

.delete-user-form {
    padding: 10px 20px
}

.delete-user-form h3 {
    color: grey;
    text-align: right;
    float: left;
    padding-right: 5px;
    margin: 0px;
    font-size: 40px;
    line-height: 40px;
}

.reassign-list {
    margin-top: rem-calc(20);
}

.reassign-list li {
    list-style: none;
    margin-left: rem-calc(10);
}